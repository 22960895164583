@import './../../common/color.scss';
.teams-section{
    color: $white-font-color;
    .section-info{
        img{
            width: 72px;
            height: 72px;
            background: transparent;
        filter: drop-shadow(0px 6px 35px $service-card-image-shadow-color);
        }
        h2{
            @include space-50-normal();
            margin: 10px;
        }
        p{
            @include space-20();
            margin: 10px;
        }
    }
    .teams-list {
        display: grid;
        grid-template-columns:repeat(4,1fr);
        justify-items: center;
        margin: 5%;
        margin-bottom: 0;
        gap: 20px;
    }
}
@include between(0,$breakpoint-mobile){
    .teams-section{
        .section-info{
            h2{
                font-size: 22px;
                line-height: 28px;
            }
            p{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .teams-list {
            grid-template-columns:repeat(1,1fr);
        }
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .teams-section{
        .section-info{
            h2{
                font-size: 22px;
                line-height: 28px;
            }
            p{
                font-size: 14px;
                line-height: 18px;
            }
        }
        .teams-list {
            grid-template-columns:repeat(2,1fr);
        }
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .teams-section{
        .section-info{
            h2{
                font-size: 40px;
                line-height: 44px;
            }
            p{
                font-size: 18px;
                line-height: 22px;
            }
        }
        .teams-list {
            grid-template-columns:repeat(2,1fr);
            // margin-left: 0;
            // margin-right: 0;
        }
    }
}
@include between($breakpoint-desktop + 1,$breakpoint-extra-large){
    .teams-section{
        .teams-list {
            grid-template-columns:repeat(3,1fr);
        }
    }
}