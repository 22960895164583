@import './../../common/color.scss';
.why-us-section{
  padding-top: 8% !important;
  padding-bottom: 5%;
  .why-us-main {
    display: flex;
    justify-content: space-around;
    margin-right: 10%;
    margin-left: 10%;
    background-size: cover;
    color: white;
  
    .why-us-left {
      width: 40%;
      padding-left: 30px;
  
      h2 {
        position: relative;
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; /* Fallback color */
        @include gilroy-25-bold();
        text-align: center;
        left: -15%;

  
        &:before {
          content: '';
          position: absolute;
          width: 72px;
          height: 2px;
          top: 50%;
          bottom: 50%;
          left: 15%;
          background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
        }
      }

      .why-us-text{

        padding-left: 15%;
        text-align: left;


        p {
          @include space-20();
          color: $whyus-font-color;
          text-align: left;
        }
        
      }
  
      
  
      
    }
  
    .why-us-right {
      width: 55%;
      display: flex;
      justify-content: center;
      align-items: center;
  
      .card-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        position: relative;
        .card {
          background-color: rgba(255, 255, 255, 0.1);
          border: 1px solid #00aaff;
          backdrop-filter: blur(10px);
          border-radius: 20px;
          padding: 15px;
          text-align: center;
          position: relative;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          clip-path: polygon(15% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 15%);
          border-image-source: #00aaff;

          &:hover {
            transform: translateY(-10px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
          }
  
          h4 {
            @include space-15-bold();
            margin-top:5px;
            margin-bottom:5px;
          }
  
          p {
            @include space-13-normal();
          }
  
      
        }

        .card::before {
          content: '';
          position: absolute;
          left: -6px; /* Move left border outside */
          right: -6px; /* Move right border outside */
          bottom: -7px; /* Move bottom border outside */
          top: 30px;
          border-left: 12px solid rgba(0, 191, 255, 0.5); /* Low-opacity border on the left */
          border-right: 12px solid rgba(0, 191, 255, 0.5); /* Low-opacity border on the right */
          border-bottom: 12px solid rgba(0, 191, 255, 0.5); /* Low-opacity border on the bottom */
          border-radius: 0 0 20px 20px; /* Make sure it follows the rounded corners */
          pointer-events: none; /* Ensure this pseudo-element doesn't affect the card's content */
          --left-border-height: 70%;
        }
  
        // Horizontal lines between cards
        .card:nth-child(1),
        .card:nth-child(2),
        .card:nth-child(3) {
          &:after {
            top: 100%;
            left: 50%;
            height: 100%;
            width: 2px;
            background-color: #00aaff;
          }
        }
      }
    }
  }
}


@include between(0,$breakpoint-mobile){
  .why-us-section{
    padding-top: 2% !important;
    
    .why-us-main{
      flex-direction: column;
      .why-us-left{
        width: 100%;
        margin-bottom: 5%;
        padding-left: 0px;
        h2:before {
          width: 35px;
          left: 25%;
        }
        .why-us-text .cm_button{
          padding: 12px 18px 12px 18px;
        }
      }
      .why-us-right{
        width: 100%;
        .card-grid {
          grid-template-columns: repeat(3, 1fr) !important;
        } 
      }
    }
  }  
}
@include max-width($breakpoint-desktop){
  .why-us-section{
    padding-top: 2% !important;
    .why-us-main{
      flex-direction: column;
      .why-us-left{
        width: 100%;
        margin-bottom: 5%;
        padding-left: 0px;
        h2:before {
          left: 12%;
        }
      }
      .why-us-right{
        width: 100%;
        .card-grid {
          grid-template-columns: repeat(2, 1fr) !important;
        } 
      }
    }
  }
}
@include max-width($breakpoint-large-desktop){
  .why-us-section{
    padding-top: 5% !important;
    .why-us-main{
      margin-right: 6% !important;
      margin-left: 6% !important; 
      .why-us-right{
        .card-grid {
          grid-template-columns: repeat(2, 1fr);
        }  
      }
    }
  }
}
@include max-width($breakpoint-extra-large){
  .why-us-section{
    .why-us-main{
      margin-right: 8%;
      margin-left: 8%; 
    }
  }
}