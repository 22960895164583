@import './../../common/color.scss'; 
.navigation {
  padding-left: 15%;
  padding-right: 15%;
  height: $nav-height;
  margin-top: 40px;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .brand {
    text-transform: uppercase;
    .brand_logo{
      width: 234px;
      height: 63.24px;
    }
    a {
      color: $nav-font-color;
      text-decoration: none;
    }
  }
  nav {
    display: flex;
    align-items: center;
    // padding-left: 10px;
    .nav-mobile {
      display: none;
    }
  
    .nav-list{
      display: flex;
      align-items: center;
      margin: 0px;
      padding: 0px;
      gap: 1rem;
      
  
      li{
        list-style: none;
        .list-link {
          color: $nav-font-color;
          text-decoration: none;
          @include space-18();
          &:hover {
            opacity: 0.5;
          }
        }
      }

    }
  }
}

@include between(0,$breakpoint-desktop) {
  .navigation {
    top: 20px;
    padding-left: 5%;
    padding-right: 5%;
    nav{
      .nav-mobile {
        display: block;   
        #nav-toggle {
          display: inline-block;
          span {
            display: block;
            width: 25px;
            height: 3px;
            margin: 5px;
            background-color: #fff;
          }
        }
      }
      .nav-list {
        display: none;
        position: absolute;
        top: 95%;
        left: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        &.active {
          display: flex;
        }
        background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
        backdrop-filter: blur(35.83000183105469px);
        border-radius: 12px;
        border: none;

        li {
          width: 100%;
          text-align: center;
          padding: 1rem 0;
          // position: relative; /* Ensure ::before is relative to li */
        }  
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 12px;
          padding: 2px 0;
          background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          mask-composite: exclude;
        }
      }
    }
  }
}
@include max-width($breakpoint-extra-large) {
  .navigation {
    padding-left: 3%;
    padding-right: 3%;
    nav{
      .nav-list {
        gap: 0.5rem;
        .header-button{
          .cm_button{
            padding: 10px 20px 10px 20px;
          }
        }
      }  
    }
  }
}
