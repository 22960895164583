@import './../../common/color.scss';
.card-stack {
  position: relative;
  width: 100%; 
  height: 456.83px;
  display: flex;
  justify-content: center; /* Align cards horizontally */
  // background: linear-gradient(to bottom right, #000, #1a1a1a);

  .card {
    position: absolute;
    width: 401.6px;
    height: 456.83px;
    
    border-radius: 41px; 
    overflow: hidden; /* Ensure images stay inside the card */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: stackIn 0.6s ease-in-out;

    &:nth-child(1) {
      transform: rotate(-8deg) translateX(-50px); /* Rotate and offset */
      z-index: 1; /* Ensure proper layering */
    }

    &:nth-child(2) {
      transform: rotate(5deg) translateX(50px);
      z-index: 2; /* On top of the first card */
    }

    &:hover {
      z-index: 3;
      transform: scale(1.05) translateY(-10px); /* Slightly zoom on hover */
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4); /* Enhanced shadow */
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover; /* Ensure images scale properly */
    }
  }

  .card::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 41px; 
    left: 0px;
    bottom: -4px;
    top: -4px;
    padding: 4.5px 0px;
    background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    -webkit-mask-composite: destination-out;
  }
}
@keyframes stackIn {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.card {
  animation: stackIn 0.6s ease-in-out;
}


@include between(0,$breakpoint-mobile){
  .card-stack {
    height: 250px;
    width: -webkit-fill-available;;
    .card {
      position: absolute;
      width: 201.6px;
      height: 228.83px;
      border-radius: 30px; 
    }
    .card::before{
      border-radius: 30px; 
    }
  }
}
@include desktop{
  .card-stack {
    height: 320px;
    width: 300px;;
    .card {
      position: absolute;
      width: 280px;
      height: 300px;
      border-radius: 30px; 
    }
    .card::before{
      border-radius: 30px; 
    }
  }
}
@include large-desktop{
  .card-stack {
    width: 350px;
    height: 400px;
    .card {
      width: 350px;
      height: 400px;
    }
  }
}
@include extra-large{
  .card-stack {
    width: 380px;
    height: 422px;
    .card {
      width: 380px;
      height: 420px;
    }
  }
}
  