.custom-swiper {
    padding-bottom: 30px;
    .swiper-button-next,
    .swiper-button-prev {
        color: transparent; /* Hide default icon */
        width: 9px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        
    }

    .swiper-button-prev {
        background-image: url('../../../public/icons/left-vector.png'); /* Replace with your custom icon path */
        left: 10px; /* Adjust position if needed */
    }

    .swiper-button-next {
        background-image: url('../../../public/icons/right-vector.png'); /* Replace with your custom icon path */
        right: 10px; /* Adjust position if needed */
    }

}
.swiper{
    width: 90%;
    .swiper-wrapper{
        .swapper-slide{
          margin-left: 40px;
        }
    }
}

.custom-swiper .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: var(--swiper-pagination-bottom, -4px);
}

.custom-swiper .swiper-pagination-bullet {
    width: 10.99px;
    height: 10.99px;
    opacity: 0px;
    background: #FFFFFF80;
    margin-top: 5%;
}
  
  .custom-swiper .swiper-pagination-bullet-active {     
    width: 47.03px;
    height: 10.99px;
    background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
    border-radius: 25px;
    opacity: 0px;

  }