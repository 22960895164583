@import './../../common/color.scss';
.dynamic-list {
    display: flex;
    flex-direction: column;
    gap: 40px; /* Space between rows */
    color: $white-font-color;
    margin-left: 10%;
    margin-right: 10%;

    .list-item {
        display: flex;
        align-items: center;
        gap: 16px;
        justify-content: space-around;
      
        &.reversed {
          flex-direction: row-reverse; 
          .image{
            text-align: right;
            padding-right: 40px;
          }
          .text{
            text-align: left;
          }
        }
      
        .image {

            flex: 1;
            width: 200px;
            height: 200px;
            text-align: left;
            padding-right: 40px;
            background: transparent;
            filter: drop-shadow(0px 6px 35px $service-card-image-shadow-color);
            img {
                width: 200px;
                height: 200px;
            }
        }
      
        .text {
          width: 544px;
          text-align: right;
          h3 {
            margin: 0 0 8px;
            font-size: 1.5rem;
            @include space-40-normal();
            text-align: left;
          }
          p {
            margin: 0;
            font-size: 1rem;
            @include space-20();
            text-align: left;
          }
        }
    }
}
  
@include between(0,$breakpoint-mobile){
  .dynamic-list {
    gap: 40px; /* Space between rows */
    margin: 0;

    .list-item {
        flex-direction: column;
        align-items: center;
        gap: 16px;
        &.reversed {
          flex-direction: column;
          .image{
            text-align: center;
          }
        }
      
        .image {
          text-align: center;
          img{
            width: 150px;
            height: 150px;
          }
        }
      
        .text {
          width: 100%;
          h3 {
            font-size: 22px;
            line-height: 24px;
          }
          p {
            font-size: 14px;
            line-height: 18px;
            text-align: left;
          }
        }
    }
  }
}
@include between($breakpoint-mobile+1,$breakpoint-tablet){
  .dynamic-list {
    gap: 40px;
    margin: 0;
    .list-item {
      .image {
        width: 150px;
        height: 150px;
        img{
          width: 150px;
          height: 150px;
        }
      }
      .text {
        width: 100%;
        h3 {
          font-size: 22px;
          line-height: 24px;
          text-align: left;
        }
        p {
          font-size: 16px;
          line-height: 20px;
          text-align: left;
        }
      }
    }
  }
}
@include between($breakpoint-tablet+1,$breakpoint-large-desktop){
  .dynamic-list {
    margin: 0;
  }
}