@import './../../common/color.scss';
.product-card-main{
    display: flex;

    .product-image {
        width: 448px;
        height: 448px;
        border-radius: 8px; 
        // backdrop-filter: blur(20px);
        position: relative; 
    }
    
    /* Simulate border with box-shadow and gradient */
    .product-image::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px; /* Same border-radius as the container */
        padding: 1px; /* Thickness of the "border" */
        background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
    }
    
    .product-image img {
        width: 448px;
        height: 448px;
        border-radius: 8px; 
    }
    
    

    .product-card-info{
        text-align: left;
        color: $white-font-color;
        padding-left: 20px;
        
  
        h2{
            margin: 0px;
            margin-bottom: 5px;
            @include space-32-normal();
            text-align:left;
        }

        .custom-list {
            list-style: none; /* Remove default bullets */
            padding: 0;
            // margin: 0;
            position: relative;
            max-height: 250px; 
            overflow: hidden;  
        }
        
        .custom-list li {
            position: relative;
            padding-left: 40px; /* Space for the bullet */
            margin-bottom: 20px; /* Space between list items */
            font-size: 18px;
            line-height: 1.6;
        }
        
        /* Custom bullet design */
        .custom-list li::before {
            content: '';
            position: absolute;
            left: 2px;
            top: 6px;
            width: 15px;
            height: 15px;
            border-radius: 50%; 
            background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%); /* Gradient border */
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }
        .custom-list li::after {
            content: '';
            position: absolute;
            left: 0px;
            top: 4px;
            right: 0;
            bottom: 0;
            width: 17px;
            height: 17px;
            border-radius: 50%; 
            border: 1px dashed rgba(21, 191, 253, 0.7);
            z-index: 1;
        }
        
    }
}

@include between(0,$breakpoint-mobile){
    .product-card-main{
        flex-direction: column;
    
        .product-image {
            width: 224px;
            height: 224px;
        }
        .product-image img {
            width: 224px;
            height: 224px;
        }
        .product-card-info{
      
            h2{
                font-size: 22px;
            }
            
            .custom-list li {
                font-size: 14px;
            }
            
        }
    }
  }
@include mobile{
    .product-card-main{
        flex-direction: column;
    
        .product-image {
            width: 300px;
            height: 300px;
        }
        .product-image img {
            width: 300px;
            height: 300px;
        }
    }
  }

@include tablet{

    .product-card-main{
        flex-direction: column;
    
        align-items:center;
        .product-image {
            margin-bottom: 2%;
        }
    }    
}
@include desktop{

    .product-card-main{    
        .product-image {
            width: 350px;
            height: 350px;
        }
        .product-image img {
            width: 350px;
            height: 350px;
        }

        .product-card-info{
            .custom-list {
                max-height: 100px; 
            }
        }
    }
}
@include large-desktop{

    .product-card-main .product-card-info .custom-list{    
        max-height: 180px; 
    }
}
@include extra-large{

    .product-card-main .product-card-info .custom-list{    
        max-height: 230px; 
    }
}