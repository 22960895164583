
@import './../../common/color.scss';
.main-custom-list {
    list-style: none; /* Remove default bullets */
    padding: 0;
    position: relative;
    // max-height:250px; 
    overflow: hidden;  

    li {
        position: relative;
        padding-left: 40px; /* Space for the bullet */
        margin-bottom: 20px; /* Space between list items */
        font-size: 18px;
        line-height: 1.6;

        &::before {
            content: '';
            position: absolute;
            left: 2px;
            top: 6px;
            width: 15px;
            height: 15px;
            border-radius: 50%; 
            background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%); /* Gradient border */
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            left: 0px;
            top: 4px;
            right: 0;
            bottom: 0;
            width: 17px;
            height: 17px;
            border-radius: 50%; 
            border: 1px dashed rgba(21, 191, 253, 0.7);
            z-index: 1;
        }
    }
}