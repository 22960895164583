@import './../../common/color.scss';

.card-main{
    width: 544px;
    height: 379px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 12px;
    box-shadow: 0 0 0 5px inset #009FFF;
    background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    backdrop-filter: blur(20px);

    
    .card-content{
        width: 444px;
        height: 314px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 5%;
        // margin-top: 8%;
        .card-image{
            height: 72px;
            width: 72px;
            background: transparent;
            filter: drop-shadow(0px 6px 35px $service-card-image-shadow-color);
        }
        .card-title{
            @include space-50-normal();
            color: $white-font-color;
            // padding-top: 20px;
            margin: 10px;
            width: 207px;
            // height: 82px;
        }
        .card-description{
            @include space-20();
            color: $white-font-color;   
            // margin-top: 8%; 
        }
    }

    &::before {
        content: "";
        position: absolute;
        inset: -3px;
        padding: 6px; /* border length  */
        background: linear-gradient(145deg, #60E7EF 2%,#000 ,#000,#000 , #60E7EF 95%);
        border-radius: inherit; 
        mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
    }
}


@include between(0,$breakpoint-tablet){
    .card-main{
        max-width: 300px;
        height: 379px;
        .card-content{
            width: 90%;
            height: 90%;
            .card-image{
                margin-bottom: 20px;
            }
            .card-title{
                font-size: 32px;
                line-height: 38px;
                width: 150px;
                text-align: center;
            }
            .card-description{
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .card-main{
        max-width: 350px;
        height: 350px;
        .card-content{
            width: 90%;
            height: 90%;
            .card-image{
                margin-bottom: 20px;
            }
            .card-title{
                font-size: 40px;
                line-height: 44px;
                width: 180px;
            }
            .card-description{
                font-size: 16px;
                line-height: 20px;
            }
        }
    }
}
@include between($breakpoint-desktop + 1,$breakpoint-large-desktop){
    .card-main{
        max-width: 450px;
        height: 350px;
        .card-content{
            width: 90%;
            height: 90%;
            // .card-image{
            //     margin-bottom: 20px;
            // }
            .card-title{
                font-size: 44px;
                line-height: 46px;
                // width: 180px;
            }
            .card-description{
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}