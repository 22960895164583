@import './../../common/color.scss';
$clip-main-path-url: url("data:image/svg+xml,%3Csvg width='521' height='543' viewBox='0 0 521 543' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_b_345_213)'%3E%3Cpath d='M0 423.65L46.2921 75.5176C46.7816 71.8364 47.738 68.2226 49.2649 64.8374C59.0451 43.1548 69.1308 31.6843 95.8164 19.7717C99.6137 18.0765 103.702 17.0945 107.843 16.7152C208.449 7.5012 291.928 5.29924 427.652 0.603427C432.195 0.446239 436.779 1.02377 441.129 2.34233C472.618 11.8861 492.745 19.8415 509.495 63.949C510.997 67.9049 511.749 72.1523 511.879 76.3818C516.186 215.692 520.637 315.849 520.147 407.659C518.73 446.728 510.447 463.608 482.19 479.175C478.709 481.092 474.839 482.344 470.93 483.061C282.761 517.576 176.679 536.657 86.5826 542.637C81.5726 542.969 76.5223 542.375 71.7705 540.753C21.1413 523.471 0.391949 498.238 0 423.65Z' fill='url(%23paint0_linear_345_213)'/%3E%3C/g%3E%3C/defs%3E%3C/svg%3E");



.client-main{
    background-image: url('./../../../public/backgrounds/Clients.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; 
    }
    
    .main-text{
        width: 100%;
        height: 108px;
        backdrop-filter: blur(10px);
        margin-top: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .description{
            margin: 0px 10%;
            text-align: center;
            align-items: center;
            @include space-20();
            color: $white-font-color;
        }
    
    }
    
    .bg-clients {
        position: relative;
        padding: 10%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            // height: 100vh;
            object-position: top;
            object-fit: cover;
        }

        .main-client-side-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 302px;
            height: 386px;
            &.left-container{
                bottom: -70px;
                left: 50px;
            }
            &.right-container{
                bottom: -95px;
                left: -30px;
            }
            


            .main-client-side-card {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                z-index: 3;
                color: $white-font-color;
                width: 302px;
                height: 386px;
                background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
                backdrop-filter: blur(20px);
                &.left-clip{
                    clip-path: path(
                        "M0.0588289 70.3769C3.09766 158.228 5.1173 206.089 11.6928 278.327C17.2551 305.95 23.139 317.38 42.2318 325.177C115.845 348.892 168.185 368.031 227.574 384.393C231.477 385.468 235.534 386.008 239.568 385.677C258.225 384.147 266.66 377.864 273.526 365.44C275.007 362.76 275.997 359.841 276.665 356.853C289.46 299.613 292.138 236.817 301.814 157.502C303.145 126.112 297.639 113.363 275.637 99.9665C200.309 64.2644 93.0501 16.3316 79.325 10.2068C78.3314 9.76336 77.3885 9.29539 76.4168 8.8058C71.2583 6.20668 53.4509 -2.1344 42.2318 0.512359C26.7563 5.9897 20.0238 12.0545 11.6928 24.3485C2.08153 38.8545 -0.435785 48.9412 0.0588289 70.3769Z"
                    );
                }
                &.right-clip{
                    clip-path: path(
                        "M301.941 70.1945C298.902 157.818 296.883 205.555 290.307 277.605C284.745 305.157 278.861 316.557 259.768 324.334C186.149 347.99 133.807 367.08 74.4117 383.401C70.5178 384.471 66.4715 385.008 62.4467 384.679C43.7879 383.156 35.3495 376.894 28.4828 364.509C26.9951 361.826 26.0016 358.902 25.3308 355.908C12.5392 298.822 9.86078 236.195 0.186249 157.094C-1.14471 125.785 4.36057 113.069 26.3626 99.7075C101.694 64.0966 208.957 16.2859 222.676 10.1797C223.669 9.73792 224.611 9.27162 225.582 8.7838C230.738 6.19234 248.548 -2.12915 259.768 0.511032C275.244 5.97418 281.976 12.0233 290.307 24.2854C299.918 38.7538 302.436 48.8144 301.941 70.1945Z"
                    );
                }
                
                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    inset: 2px;
                    background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
                    mask: conic-gradient(#000 0 0) content-box exclude, conic-gradient(#000 0 0);
                }


                .client-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    top: 70px;
                    .vector-image{
                        width: 31px;
                        height: 31px;
                    }
                    

                    .client-card-title{
                        @include space-22-bold();
                        margin-bottom: 0px;
                    }

                    .client-card-desc{
                        @include space-10();
                        margin-top: 0px;
                        color: #CFCFCF;
                        max-width: 192px;
                        max-height: 218px;
                        overflow: hidden;
                    }
                }

            
            }
            
            
            .profile-image {
                position: absolute;
                width: 76px;
                height: 76px;
                object-fit: cover;
                border-radius: 50%;
                top: 0px;
                z-index: 5;
            }
            .bg-side-overlay{
                position: absolute;
                width: 302.67px;
                height: 150%;
                background: #7838A6;
                filter: blur(50px);
                opacity: 0.15;
                &.left-bg-clip{
                    left: -14px;
                    top: 0;
                    clip-path: path(
                        "M12.8197 63.7388C5.87972 151.221 2.45994 198.933 0.796579 271.456C3.18338 299.627 7.72396 311.801 25.7766 322.279C98.4137 357.514 149.093 384.638 208.548 410.118C233.602 413.074 243.346 407.031 253.168 391.767C273.365 335.829 283.155 272.789 302.225 193.574C307.102 162.664 303.087 149.239 282.783 132.793C207.166 83.3169 96.8743 14.6928 96.8743 14.6928C96.8743 14.6928 76.0395 -0.587893 62.5624 0.59713C46.5933 3.79272 39.2289 8.83104 29.5733 19.8108C18.3974 32.7977 14.7579 42.4288 12.8197 63.7388Z"
                    );
                }
                &.right-bg-clip{
                    right: -14px;
                    top: 0;
                    clip-path: path("M291.44 63.5751C298.38 150.852 301.8 198.452 303.463 270.804C301.076 298.91 296.535 311.055 278.483 321.509C205.845 356.661 155.164 383.721 95.7094 409.141C70.6542 412.09 60.9102 406.062 51.0883 390.834C30.8908 335.027 21.1007 272.135 2.0304 193.106C-2.84632 162.268 1.16931 148.874 21.4731 132.467C97.0906 83.1072 207.384 14.6442 207.384 14.6442C207.384 14.6442 228.219 -0.600598 241.696 0.581644C257.666 3.76973 265.03 8.79623 274.686 19.7502C285.862 32.7066 289.501 42.3151 291.44 63.5751Z");
                }
            }
        }
        .main-client-container {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 522px;
            height: 544px;
            // background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
           
            .main-client-card {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                z-index: 2;
                color: $white-font-color;
                width: 520.18px;
                height: 542.75px;
                box-sizing: border-box;
                background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
                // background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
                backdrop-filter: blur(22px);
                
                
                .client-info{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    top: 70px;
                    max-width: 433px;
                    .client-card-title{
                        @include space-32-bold();
                        margin-bottom: 5px;
                    }

                    .client-card-desc{
                        @include space-20();
                        margin-top: 5px;
                        color: #CFCFCF;
                    }
                }

                // &::before {
                //     content: "";
                //     position: absolute;
                //     top: 0;
                //     left: 0;
                //     right: 0;
                //     bottom: 0;
                //     border: 2px solid red;
                //     clip-path: inherit;
                //     z-index: 1; /* Increase z-index */
                // }
            
            }
            
            // .main-client-card::before{
            //     content: '';
            //     width: 518.18px;
            //     height: 540.75px;
            //     // background: #00c000;
            //     background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
            //     display: block;
            //     position: absolute;
            //     backdrop-filter: blur(22px);
            //     top: 1px;
            //     left: 1px;
            //     right: 1px;
            //     bottom: 1px;
            // }
            .main-client-card{
                -webkit-clip-path: path(
                    "M0 423.65L46.2921 75.5176C46.7816 71.8364 47.738 68.2226 49.2649 64.8374C59.0451 43.1548 69.1308 31.6843 95.8164 19.7717C99.6137 18.0765 103.702 17.0945 107.843 16.7152C208.449 7.5012 291.928 5.29924 427.652 0.603427C432.195 0.446239 436.779 1.02377 441.129 2.34233C472.618 11.8861 492.745 19.8415 509.495 63.949C510.997 67.9049 511.749 72.1523 511.879 76.3818C516.186 215.692 520.637 315.849 520.147 407.659C518.73 446.728 510.447 463.608 482.19 479.175C478.709 481.092 474.839 482.344 470.93 483.061C282.761 517.576 176.679 536.657 86.5826 542.637C81.5726 542.969 76.5223 542.375 71.7705 540.753C21.1413 523.471 0.391949 498.238 0 423.65Z"
                );
                clip-path: path(
                    "M0 423.65L46.2921 75.5176C46.7816 71.8364 47.738 68.2226 49.2649 64.8374C59.0451 43.1548 69.1308 31.6843 95.8164 19.7717C99.6137 18.0765 103.702 17.0945 107.843 16.7152C208.449 7.5012 291.928 5.29924 427.652 0.603427C432.195 0.446239 436.779 1.02377 441.129 2.34233C472.618 11.8861 492.745 19.8415 509.495 63.949C510.997 67.9049 511.749 72.1523 511.879 76.3818C516.186 215.692 520.637 315.849 520.147 407.659C518.73 446.728 510.447 463.608 482.19 479.175C478.709 481.092 474.839 482.344 470.93 483.061C282.761 517.576 176.679 536.657 86.5826 542.637C81.5726 542.969 76.5223 542.375 71.7705 540.753C21.1413 523.471 0.391949 498.238 0 423.65Z"
                );
                border: 2px solid red; 
            }
            
            .profile-image {
                position: absolute;
                width: 127.85px;
                height: 127.85px;
                object-fit: cover;
                border-radius: 50%;
                top: -50px;
                z-index: 5; /* Ensure it appears above everything else */
            }
            .bg-overlay{
                position: absolute;
                right: 0;
                top: 0;
                width: 476px;
                height: 475px;
                background: #7838A6;
                // z-index: -1;
                filter: blur(10px);
                opacity: 0.1;
                clip-path: path("M57.9865 0.364014C18.7132 10.3112 7.79973 20.9263 0.939941 45.5821L23.5618 474.171H441.575C467.569 460.296 475.545 439.626 476 375.87C463.095 247.934 447.347 175.007 423.871 45.5821C412.034 27.6397 409.461 15.6593 362.89 14.126L57.9865 0.364014Z");
            }
        }

          
    }

    .client-video-container{
        display: flex;
        justify-content: center;
        // align-items: flex-end;
        // padding: 50px 0; /* Add some padding for spacing */
        color: $white-font-color;

          
        .testimonial-card {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width:  928px;
            height: 909px;
            border: 2px solid red;
            .video {
                width: 200px; /* Adjust size as needed */
                height: 150px;
                border-radius: 10px; 
                margin-right: 30px;
                object-fit: cover; /* Ensure video fills the container */

                width:  (928px);
                height:  (469px);
                top: 1223px;
                left: 256px;
                padding: 0px 4px 0px 4px;
                gap: 10px;
                border-radius: 30px 30px 0px 0px;
                border: 1px 0px 0px 0px;
                opacity: 0px;

            }
            
            border-radius: 30px ;


            .testimonial-card {
                position: relative; /* To position the video */
                
               
            }
              

              
              .testimonial-content {
                flex-grow: 1; /* Allow content to take up remaining space */
              }
              
              .testimonial-content h2 {
                font-size: 24px;
                margin-bottom: 10px;
              }
              
              .testimonial-content p {
                color: #666;
                font-size: 16px;
                line-height: 1.5;
              }
              
              /* Optional: Styling for the rating component */
              .rating {
                margin-top: 15px;
              }
        }
          
        
    }
}

@include between(0,$breakpoint-mobile){
    .client-main{
        .heading-text{
            margin-top: 5%;
            font-size: 40px;
            line-height: 45px;
        }
        
        .main-text{
            margin-top: 4%;    
            .description{
                margin: 0px 5%;
                font-size: 14px;
                line-height: 16px;
            }
        
        }
        
        .bg-jobs {
            .clients-form-container {
                margin:5%;   
                .form-title {
                    font-size: 32px;
                    line-height: 34px;
                }
                .clients-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            flex-direction: column;
                            label {
                                font-size: 18px;
                                line-height: 20px;
                            }
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                        .consent-label {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .client-main{
        .bg-jobs {    
            .clients-form-container {
                .form-title {
                    @include space-40-bold();
                    text-align: center;
                    margin: 20px;
                }
                .clients-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            display: flex;    
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-large-desktop){
    .client-main{
        .bg-jobs {    
            .clients-form-container {
                .clients-form {
                    .form-row {
                        .check-services{ 
                            .checkbox-container {
                                grid-template-columns: repeat(2,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}