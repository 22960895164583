@import './../../common/color.scss';
.team-card {
    display: flex;
    flex-direction: column;
    color: $white-font-color;
    // margin-left: 10%;
    // margin-right: 10%;
    width: 248px;
    height: 352px;
    position: relative;
    border-radius: 12px;


    &::before {
        content: "";
        position: absolute;
        inset: -1px;
        padding: 1px; /* border length  */
        background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
        border-radius: inherit; 
        mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
    }
    

    .profile-image{
        width: 248px;
        height: 248px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        img{
            width: 248px;
            height: 248px;
            object-fit: cover;
            border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        }
    }

    .icon-linkedin{
        position: absolute;
        top: 5px;
        left: 5px;
    }

    .card-info{
        // width: 184px;
        padding-top: 20px;

        .card-title{
            @include space-28-bold();
        }
        .card-description{
            @include space-20();
        }
    }
}
  
  
  