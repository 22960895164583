@import './../../common/color.scss';


.services-main{
    background-image: url('./../../../public/backgrounds/Services.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; 
    }
    
    .main-text{
        width: 100%;
        height: 108px;
        backdrop-filter: blur(10px);
        margin-top: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .description{
            margin: 0px 10%;
            text-align: center;
            align-items: center;
            @include space-20();
            color: $white-font-color;
        }
    
    }
    
    .benefits-overview{
        margin: 10%;
        margin-top: 0;
        margin-bottom: 5%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        color: $white-font-color;
        .overview-title{
            @include space-40-normal();
            text-align: left; 
            margin: 25px 0;
        }

        .overview-grid{
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2,1fr);
            .overview-card{
                .o-card-title{
                    @include space-20();
                    text-align: left;
                    background: $theme-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    color: $themeBlackBackgroundColor; 
                }
                .o-card-desc{
                    @include space-20();
                    text-align: left;
                }
            }
        }
    }    
    .our-process{
        padding: 10%;
        padding-top: 5%;
        padding-bottom: 5%;
        display: flex;
        justify-content: left;
        color: $white-font-color;
        backdrop-filter: blur(10px);
        background: #FFFFFF0A;



        .process-info{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            width: 50%;
            .process-title{
                @include space-40-normal();
                text-align: left; 
                margin: 0;
            }
            .process-desc{
                @include space-20;
                text-align: left; 
                margin: 25px 0;
            }
        }

        .process-grid{
            width: 100%;
            height: 100%;
            display: grid;
            gap: 20px;
            grid-template-columns: repeat(2,1fr);
            justify-items: center;
            .process-card{
                width: 306px;
                height: 306px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                border-radius: 50%;
                backdrop-filter: blur(35.83000183105469px);
                box-shadow: 0px 0px 59.2px 0px #2199D414;
                position: relative;

                .p-card-count{
                    @include space-70();
                    width: 216px;
                    background: $theme-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    // box-shadow: 0px 0px 59.2px 0px #2199D480;
                    margin: 10px;
                }
                .p-card-title{
                    @include space-30-bold();
                    margin: 5px;
                    width: 216px;
                }
                .p-card-desc{
                    width: 216px;
                    @include space-15();
                    line-height: 20px;
                    letter-spacing: 0.7200000286102295px;
                }

                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    padding: 1px;
                    width: 100%;
                    height: 100%;
                    border-radius: inherit;
                    border: 1px solid transparent;
                    background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
                    mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
                }
            }
        }
    }    
}

@include between(0,$breakpoint-mobile){
    .services-main{
        .main-text{
            width: 100%;
            .description{
                margin: 0px 2%;
                font-size: 18px;
                line-height: 20px;
            }        
        }
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(1,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
    
            .process-grid{
                grid-template-columns: repeat(1,1fr);
            }
        }    
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .services-main{
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
            .process-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .services-main{
       
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
    
            .process-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
    }
}