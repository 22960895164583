@import './../../common/color.scss';

.input-main-div{
    position: relative;
    border: none;
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: $white-font-color;

    label {
        @include space-25-normal();
        padding-bottom: 10px;
    }
    
    .input-gradient-bordered {
        padding: 10px 15px;
        outline: none;
        background: transparent;
        position: relative;
        z-index: 2; 
        border: 1px solid transparent;
        /* Apply gradient to the border */
        border-image-source: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
        border-image-slice: 1; /* Ensures the gradient fills the entire border */
    }
    
    input, select, textarea , input[type="checkbox"] {
        color: $white-font-color;
        padding: 10px;
        background-color: transparent;
        @include space-22-light();
    }
    input::placeholder {
        @include space-22-light();
        opacity: 50%;
    }
    textarea::placeholder {
        @include space-22-light();
        opacity: 50%;
    }
    select {
        appearance: none;
        background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><defs><linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%"><stop offset="28.27%" stop-color="%2360E7EF"/><stop offset="80.21%" stop-color="%23009FFF"/></linearGradient></defs><path fill="url(%23gradient)" d="M4 6l4 4 4-4z"/></svg>');
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 20px;
        border: 1px solid transparent;
        /* Apply gradient to the border */
        border-image-source: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
        border-image-slice: 1;
        -webkit-appearance: none; /* For Safari */
        -moz-appearance: none; /* For Firefox */
        background-color: transparent; /* Transparent background */
    }
    input:focus, select:focus {
        outline: none;
        border-image-source: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
        border-image-slice: 1;
    }
    select option {
        background-color: transparent; /* Transparent background for options */
        color: #000; /* Text color for the options */
        border: none; /* Remove borders from options */
    }
    .select option:hover {
        background-color: rgba(0, 0, 0, 0.1); /* Light hover background */
    }
}


.custom-checkbox input[type="checkbox"] {
    display: none;
  }
  .custom-checkbox {
    display: flex;
    align-items: center;
    gap: 10px; 
    cursor: pointer;
  }
  
  .custom-checkbox .checkmark {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    background: transparent; 
    border: 2px solid transparent; 
    border-image-source: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
    border-image-slice: 1;
  }
  
  .custom-checkbox input[type="checkbox"]:checked + .checkmark::after {
    content: '';
    position: absolute;
    top: 40%; 
    left: 50%; 
    width: 5px; 
    height: 10px; 
    border: solid transparent; 
    border-width: 0 2px 2px 0; 
    transform: translate(-50%, -50%) rotate(45deg); 
    background: transparent; 
    border-image-source: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
    border-image-slice: 1;
  }  


@include between(0,$breakpoint-mobile){
    .input-main-div{
        
        label,input, select, textarea , input[type="checkbox"],input::placeholder,textarea::placeholder {
            font-size: 18px;
            line-height: 20px;
        }
    }
}
@include between($breakpoint-tablet +1,$breakpoint-desktop ){
    .input-main-div{
        width: 40%;
    }
}






