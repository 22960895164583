@import './../../common/color.scss';

.client-section{
    padding-top: 25px;
    padding-bottom: 25px;
   
    
    .client-info{
      text-align: center;
      padding-bottom: 20px;
      .section-title {
          margin-bottom: 20px;
          display: inline-block;
          position: relative;
          background: $theme-gradient;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: $themeBlackBackgroundColor; /* Fallback color */
          @include gilroy-25-bold();
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          width: 100px; // Adjust the line length
          height: 2px;
          background: $theme-gradient;
        }      
        &:before {
          left: -120px; // Adjust the left line position
        }
    
        &:after {
          right: -120px; // Adjust the right line position
        }
      }
    
        
      .client-view{
          max-width: -webkit-fill-available;
          height: 52px;
          .client-image{
              margin-left: 10px;
              height: 52px;
              width: 160px;
          }
      }
    }
}


@include between(0,$breakpoint-mobile){
  .client-section{
   
    .client-info{
      text-align: center;
      padding-bottom: 10px;
      .section-title {
          font-size: 18px;
        &:before,
        &:after {
          width: 25px; // Adjust the line length
        }      
        &:before {
          left: -25px; // Adjust the left line position
        }
    
        &:after {
          right: -25px; // Adjust the right line position
        }
      }
    }
  }
}