@import './../../common/color.scss';

.job-card {
    width: 352px;
    height: 239px;
    padding: 20px;
    position: relative;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 4px solid #FFFFFF1A;
    border-radius: 12px;
    border-left: 0;
    border-right: 0;
    background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    
    .background {
        position: absolute;
        bottom: 0;
        right: 0;
        object-fit: cover;
        opacity: 0.5px;
        z-index: 1;
    }
    
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        border-radius: inherit;
        backdrop-filter: blur(3px);
        background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
    }
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5);
    }

    .jobDetail{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 2;
        .tags {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px !important;
          .cm_button{
            padding: 8px 16px !important;
          }
          .cm_button::before{
            background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%) !important;
          }
        }
        
        .job-info{
          .title{
            @include space-25-bold();
            text-align: left;
            margin: 0;
            margin-bottom: 5px;
          }
          .description{
            @include space-15();
            text-align: left;
            margin: 0;
            margin-bottom: 5px;
          }
        }
      
      
        .applicants-action{
          display: flex;
          justify-content: space-between;
          .applicants {
            @include space-15();
            display: flex;
            align-items: center;
            gap: 5px;
          }

        }
    }
  
  }
  