@import './../../common/color.scss';

.btn-main-div{
    position: relative;
    width: fit-content;
}

.cm_button{
    width: Fixed (157px)px;
    height: Fixed (48px)px;
    top: 7.62px;
    left: 960px;
    padding: 12px 24px 12px 24px;
    gap: 10px;
    opacity: 1px;
    border: none;
    z-index: 1;
    @include space-17-bold();


    background: $btn-background-gradient;

    &.btn-border-round{
        padding: 15px 32px 15px 32px;
        border-radius: $btn-border-radius !important;
    
    }
  

    &.btn-bordered {
        border: $btn-border solid; /* Border is initially transparent */
        background: $btn-background-black; /* Black background */
        color: $btn-default-font-color; /* Text color for black background */
        border-radius: $btn-border-radius;
    }

    &.btn-gradient-bordered{
        color: $btn-default-font-color; 
        border-radius: 12px;
        background: transparent;
    }
    &.btn-gradient-bordered::before{
        content:"";
        position: absolute;
        width: 100%;
        height: 100%;
        top: -2px;
        left: -2px;
        border-radius: inherit; 
        padding:2px;
        background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask-composite: exclude;
        -webkit-mask-composite: destination-out;
    }
}


