@import './../../common/color.scss';

.tabs {
    display: flex;
    justify-content: center;
    justify-content: space-around;
    padding-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
  
    .tab {
        @include space-20();
        color: $white-font-color;
        padding: 10px 0;
        cursor: pointer;
        border: none;
        background: none;
        position: relative;
  
        &.active::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            padding-bottom: 2px;
            border-radius: inherit;
            background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
            mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
        }
    }
  }
  