@import './../../common/color.scss';

.main-unorder-list {
    list-style: none; 
    padding-left: 0px;
    text-align: left;
  }
  
  .main-unorder-list li {
    position: relative; 
    padding-left: 50px;
    padding-bottom: 50px;
    display: flex;
    align-items: center;
    @include space-20();
    text-align: left 
  }
  
  .main-unorder-list li::before {
    content: '';
    position: absolute;
    left: 0;
    // top: 50%;
    // transform: translateY(-50%);
    width: 35px;
    height: 35px;
    background-image: url('./../../../public/images/check-icon.png'); 
    background-size: contain;
    background-repeat: no-repeat;
  }
  .main-unorder-list li::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 30%;
    width: 352px;
    height: 2px;
    background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%, #021216 100%);

  }
  .main-unorder-list li:last-child::after {
    content: none;
  }