@import './../../common/color.scss';
.product-section {
    // height: 706px;
    padding: 49px 30px 49px 30px;
    backdrop-filter: blur(11px);
    background: $backdrop-background-color;

    .section-title {
        margin-bottom: 20px;
        display: inline-block;
        position: relative;
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; /* Fallback color */
        @include gilroy-25-bold();
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 100px;
        height: 2px;
        background: $theme-gradient;
      }      
      &:before {
        left: -120px; 
      }
  
      &:after {
        right: -120px;
      }
    }

    .section-description{
        margin-top: 0px;
        margin-bottom: 0px;
        @include space-20();
        color: $white-font-color;
    }

    .product-card-section{
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 10%;
        margin-right: 10%;
        // display: flex;
        // justify-content: center;
        // flex-wrap: wrap; 
    }
  }


  @include between(0,$breakpoint-mobile){
    .product-section {
      padding: 39px 20px 39px 20px;
  
      .section-title {
          font-size: 20px;
        &:before,
        &:after {
          width: 25px;
        }      
        &:before {
          left: -40px; 
        }
    
        &:after {
          right: -40px;
        }
      }
  
      .section-description{
          font-size: 14px !important;
      }
  
      .product-card-section{
        margin-left: 5%;
        margin-right: 5%;
      }
    }
  }
  @include between($breakpoint-mobile,$breakpoint-desktop){
    .product-section {
      
      .product-card-section{
          margin-left: 4%;
          margin-right: 4%;
      }
    }
  
  }