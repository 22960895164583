@import './../../common/color.scss';

.overview-section{
    margin: 15%;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $white-font-color;

    .overview-text{
        width: 70%;
        .overview-heading{
            @include space-40-bold();
            text-align: left;
            margin-bottom: 0px;
        }
        .overview-description{
            @include space-20();
            text-align: left;
        }

    }
    .overview-card{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        .overview-image{
            height: 492px;
            width: 736px;
            border-radius: 12px;
            position: relative;
            img{
                height: 100%;
                width: 100%;
                border-radius: 12px;
                object-position: cover;
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                border-radius: inherit; 
                padding: 1px; /* Thickness of the "border" */
                background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask-composite: exclude;
                -webkit-mask-composite: destination-out;
            }
        }
    }
    .overview-card-info{
        text-align: left;
        padding: 35px;
        padding-top: 100px;
        .baseLine{
            content: "";
            margin-bottom: 10px;
            width: 256px;
            height: 1px;
            background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%, #021216 100%);
        }

    }
    
}
.count-main{
    .heading-text{
        margin: 0px;
        @include space-50-normal();
        text-align: left !important;
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; /* Fallback color */
    }
    .count-description{
        @include space-20();
        margin-top: 10px;
        margin-bottom: 10px;
        text-align: left;
    }
}

@include between(0,$breakpoint-mobile){
    .overview-section{
        margin: 5%;
        margin-top: 30%;
        .overview-text{
            width: 100%;
            margin-bottom: 3%;
            .overview-heading{
                font-size: 22px;
                line-height: 28px;
                text-align: justify;
            }
            .overview-description{
                font-size: 14px;
                line-height: 18px;
                text-align: justify;
            }
        }
        .overview-card{
            grid-template-columns: repeat(1,1fr);
            .overview-image{
                height: 250px;
                width: 280px;
            }
        }
        .overview-card-info{
            padding-top: 40px;  
            padding-right: 0;  
        }
        
    }
}
@include between($breakpoint-mobile+1,$breakpoint-tablet){
    .overview-section{
        margin: 5%;
        margin-top: 15%;
        .overview-text{
            width: 100%;
            .overview-heading{
                font-size: 22px;
                line-height: 28px;
                text-align: justify;
            }
            .overview-description{
                font-size: 16px;
                line-height: 20px;
                text-align: justify;
            }
        }
        .overview-card{
            grid-template-columns: repeat(1,1fr);
            .overview-image{
                width: 500px;
            }
        }  
        .overview-card-info{
            // padding-top: 40px;  
            padding-right: 0;  
        }      
    }
}
@include between($breakpoint-tablet+1,$breakpoint-desktop){
    .overview-section{
        margin: 5%;
        margin-top: 15%;
        .overview-text{
            .overview-heading{
                font-size: 38px;
                line-height: 40px;
            }
        }
        .overview-card{
            .overview-image{
                width: 500px;
            }
        }  
        .overview-card-info{
            // padding-top: 40px;  
            padding-right: 0;  
        }      
    }
}
@include between($breakpoint-desktop+1,$breakpoint-large-desktop){
    .overview-section{
        margin: 10%;
        .overview-card{
            .baseLine{
                width: 200px;
            }
            .overview-image{
                width: 650px;
            }
        }  
        .overview-card-info{ 
            padding-right: 0;  
        }      
    }
}
@include between($breakpoint-large-desktop+1,$breakpoint-extra-large){
    .overview-section{
        .overview-card{
            overflow: hidden;
            .baseLine{
                width: 200px;
            }
            .overview-image{
                width: 650px;
            }
        }        
    }
}