@import './../../common/color.scss';

.meeting-section{
    padding-top: 25px;
    padding-bottom: 25px;
   
    .meeting-info{
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        padding-bottom: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .meeting-images-section{
            
            width: 100%;
            height: 100%;

            

            .meeting-image1{
                width: 401.6px;
                height: 456.83px;
                // border-radius: 41px; 
                // backdrop-filter: blur(20px);
                // position: relative;
                // img{
                //     width: 401.6px;
                //     height: 456.83px;
                //     border-radius: 38px;
                // }
            }
            .meeting-image1::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 41px; 
                left: 0px;
                bottom: 0px;
                top: -4px;
                padding: 3.5px 0px;
                background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask-composite: exclude;
                -webkit-mask-composite: destination-out;
                // width: 64.82px;
                height: 550px;
            }
           
            .meeting-image2{
                width: 401.6px;
                height: 456.83px;
                border-radius: 41px; 
                backdrop-filter: blur(20px);
                position: relative;
                img{
                    width: 401.6px;
                    height: 456.83px;
                    border-radius: 38px;
                }
            }
            .meeting-image2::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 41px; 
                left: 0px;
                bottom: 0px;
                top: -4px;
                padding: 3.5px 0px;
                background: linear-gradient(152.14deg, rgba(21, 191, 253, 0.7) 9.96%, rgba(156, 55, 253, 0.7) 100%);
                -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                mask-composite: exclude;
                -webkit-mask-composite: destination-out;
            }

        }

        .meeting-detail{
            padding-top: 20px;
            padding-left: 10px;
            text-align: left;
            .detail-heading{
                @include space-38-bold();
                color: $white-font-color;
            }
            p{
                @include space-20();
                color:$white-font-color;
                text-align: left;
            }
        }
    }
  
    
}


@include between(0,$breakpoint-mobile){
    .meeting-info{
        grid-template-columns: repeat(1, 1fr) !important;
       
        .meeting-detail{
            padding-top: 20px;
            padding-left: 10px;
            text-align: left;
            .detail-heading{
                font-size: 22px !important;
                line-height: 30px !important;
            }
            p{
                font-size: 14px !important;
            }
            .cm_button{
                padding: 12px 18px !important
            }
        }
    }
}
@include tablet{
    .meeting-info{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}
@include desktop{
    .meeting-info{
        .meeting-detail{
            padding-left: 30px !important;
            .detail-heading{
                font-size: 28px !important;
                line-height: 38px !important;
            }
            p{
                font-size: 16px !important;
            }
            .cm_button{
                padding: 12px 18px !important
            }
        }
    }
}
