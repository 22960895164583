


@import './common/color.scss'; 

@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

#root {
  text-align: center;
  background-color: $themeBlackBackgroundColor;
  height: max-content;
}
body {
  font-family: 'Space Grotesk', sans-serif;
}
