@import './../../common/color.scss';

.clutch-section{
    margin-top: 8%;
    margin-bottom: 2%;
    .clutch-view{
        max-width: -webkit-fill-available;
        .clutch-image{
            margin-left: 10px;
        }
    }
}

@include extra-large{
    .clutch-section{
        margin-top: 0;
        margin-bottom: 0;
    }
}
