@import './../../common/color.scss';


.about{
    background-image: url('./../../../public/backgrounds/About Us.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; /* Fallback color */
    }
    
    .aboutus-text{
        width: 100%;
        height: 225px;
        backdrop-filter: blur(10px);
        margin-top: 6%;
        display: flex;
        align-items: center;
        z-index: 2;
        position: absolute;

        .about-description{
            margin: 0px 10%;
            text-align: center;
            align-items: center;
            @include space-20();
            color: $white-font-color;
        }
    
    }
    .about-cards{
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        gap: 8%;
        top: 70px;
        margin-bottom: 10%;
        z-index: 9;
    }
    .about-section{
        margin: 10%;
    }
    .team{
        margin: 10%;
        margin-bottom: 0;
    }
    
    .main-img{
        position: relative;
        top: 170px;
        .main-about-image{
            max-width: 100%;
            width: 100%;
        }

    }

    
}

@include between(0,$breakpoint-tablet){
    .about{
        .heading-text{
            margin-top: 20px;
            font-size: 40px;
            line-height: 52px;
        }
        
        .aboutus-text{
            .about-description{
                margin: 0px 5%;
                padding: 5px;
                font-size: 14px;
                line-height: 18px;
            }        
        }
        .about-cards{
            flex-direction: column;
            gap: 10px;
        }
        .teams{
            margin: 0 !important;
        }
    }
}

@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .about{
        .about-cards{
            gap: 10px;
        }
        .team{
            margin: 5% !important;
        }
    }
}
@include between($breakpoint-desktop + 1,$breakpoint-extra-large){
    .about{
        .about-cards{
            gap: 25px;
        }
    }
}
