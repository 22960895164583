@import './../../common/color.scss';

.testimonal-section{
    padding-bottom: 25px;
   
    
    .testimonal-info{
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        padding-bottom: 20px;
        .section-title {
            margin-bottom: 20px;
            display: inline-block;
            position: relative;
            background: $theme-gradient;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: $themeBlackBackgroundColor; /* Fallback color */
            @include gilroy-25-bold();
          &:before,
          &:after {
            content: '';
            position: absolute;
            top: 50%;
            width: 100px; // Adjust the line length
            height: 2px;
            background: $theme-gradient;
          }      
          &:before {
            left: -120px; // Adjust the left line position
          }
      
          &:after {
            right: -120px; // Adjust the right line position
          }
        }
        .section-description{
            //styleName: description;
            margin-top: 0px;
            margin-bottom: 0px;
            @include space-20();
            color: $white-font-color;
        }
        
        .testimonal-view{
            max-width: -webkit-fill-available;
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: row;


            .swiper-button-prev {
                background-image: url('../../../public/testimonials/arrowleft.png'); /* Replace with your custom icon path */
                left: 10px; /* Adjust position if needed */
                width: 40px;
                height: 40px;
            }
        
            .swiper-button-next {
                background-image: url('../../../public/testimonials/arrowright.png'); /* Replace with your custom icon path */
                right: 10px; /* Adjust position if needed */
                width: 40px;
                height: 40px;
            }
        }
    }
}

@include between(0,$breakpoint-mobile){
  .testimonal-section{
    padding-bottom: 25px;
    .testimonal-info{
        margin-left: 5%;
        margin-right: 5%;
        .section-title {
          font-size: 20px;
          &:before,
          &:after {
            width: 25px; // Adjust the line length
          }      
          &:before {
            left: -40px; // Adjust the left line position
          }      
          &:after {
            right: -40px; // Adjust the right line position
          }
        }
        .section-description{
            font-size: 14px;
        }
        .testimonal-view{
          .swiper{
            padding-bottom: 70px !important;
            width: 100%;
          }
            .swiper-button-prev,.swiper-button-next {
                width: 30px;
                height: 30px;
            }
            .swiper-button-next{
              right: 0px;
            }
            .swiper-button-prev{
              left: 0px;
            }
        }
    }
  }
}

@include tablet{
  .testimonal-section{
    .testimonal-info{
        .testimonal-view{
          .swiper{
            .swiper-wrapper{
              transform: translateX(15%);
            }
          }
        }
    }
  }
}

@include desktop{
  .testimonal-section{
    .testimonal-info{
        .testimonal-view{
          .swiper{
            .swiper-wrapper{
              transform: translateX(25%);
            }
          }
        }
    }
  }
}
