@import './../../common/color.scss';

.main-card{
    width: 300px;
    height: 300px;
    padding: 34px;
    padding-top: 50px;
    padding-bottom: 70px;
    // gap: 10px;
    border-radius: 41.67px;
    border: 2.5px solid $service-card-border-color;
    border-left: 0px;
    border-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);

    // flex-basis: calc(33.333% - 20px);
    max-width: 300px;
    margin-bottom: 4%;  


    .card-image{
        height: 69px;
        width: 69px;
        background: transparent;
        filter: drop-shadow(0px 6px 35px $service-card-image-shadow-color);
    }
    .card-title{
        @include space-32-normal();
        color: $white-font-color;
        padding-top: 20px;
        margin: 0px;
        width: 207px;
        height: 82px;
    }
    .card-description{
        @include space-20();
        color: $white-font-color;
        margin-top: 20px;
        width: 280px;
        height: 116px;

    }



}

@include between(0,$breakpoint-mobile){
    .main-card{
        width: 250px;
        height: 250px;
        padding: 20px;
        margin-left: 10px;
        max-width: 250px;
        margin-bottom: 4%;  
        .card-title{
            font-size: 22px;
        }
        .card-description{
            font-size: 14px;
            margin-top: 10px;
        }
    }
  }