@import './../../common/color.scss';

.footer-section{
    padding-bottom: 25px;
   
    
    .footer-main{
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        .footer-baseLine{
            content: "";
            width: 100%;
            height: 1px;
            background: linear-gradient(90deg, #021216 7.79%, #60E7EF 22.4%, #009FFF 74.69%, #021216 91.21%);
        }

        .footer-grid-row{
            margin-top: 50px;
            margin-bottom: 50px;
            // display: flex;
            display: grid;
            grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
            gap: 1rem;

            .footer-grid-col{
                // width: 25%;
                // margin-right: 40px;
                text-align: left;

                .brand {
                    text-transform: uppercase;
                    font-size: 1.4em;
                    display: flex;
                  
                    .brand_logo{
                      width: 234px;
                      height: 63.24px;
                    }
                }
                .brand-description{
                    @include space-15();
                    color: $whyus-font-color;
                    text-align: left;
                }
                .footer-terms-btn{
                    display: flex;
                    @include space-15();
                    font-size: 12px;
                    a{
                        margin-right: 10px;
                        text-decoration: none;
                        color: $white-font-color;
                    }
                    // justify-content: space-between;

                }
                .heading{

                    @include space-20();
                    color: $white-font-color;
                    text-align: left;
                    padding-left: 20px;

                }
                .footer-contact{
                    @include space-15();
                    display: flex;
                    color:$whyus-font-color;
                    text-align: left;
                    a{
                        margin-right: 8px;
                        margin-left: 8px;
                        text-decoration: none;
                        color:$whyus-font-color;
                    }
                }
                ul {
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    padding-left: 20px;
                    // display: flex;
                    align-items: left;
                    
                    li {
                    //   position: relative;
                    padding: 5px 5px;
                
                      a {
                        // display: block;
                        color: $whyus-font-color;
                        text-decoration: none;
                        // position: relative;
                        @include space-18();
                
                
                      }
                    }
                }
            }
        }

    }
    .footer-row{
        height: 1px;
        background-color: $footer-line-color; 
        border: none;
    }
    .footer-copyright{
        text-align: center;
        padding: 10px;
        @include space-17();
        color: $footer-text-color;
        
    }
  
    
}

@include between(0,$breakpoint-tablet) {
    .footer-grid-row{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}
@include desktop {
    .footer-grid-row{
        grid-template-columns: repeat(2, 1fr) !important;
    }
}
@include large-desktop {
    .footer-grid-row{
        grid-template-columns: repeat(3, 1fr);
    }
}
@include extra-large {
    a{
        margin-right: 5px !important;
    }    
}


