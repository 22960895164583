@import './../../common/color.scss';


.background-div {
    position: relative;
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(182.97deg, rgba(0, 0, 0, 0) 29.08%, #000407 97.13%);
}
.d-card-main{
    margin: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    display: flex;
    color: $white-font-color;
    
    .d-card-left{
        width: 638px;
        height: 412px;
        position: relative;
        border-radius: 12px;
        background: #009FFF;
        img{
            width: 638px;
            height: 412px;
            object-fit: cover;
            border-radius: inherit;
        }
        &::before {
            content: "";
            position: absolute;
            inset: -1px;
            padding: 2px; 
            background: linear-gradient(135deg, #60E7EF 28.27%, #009FFF 80.21%,#60E7EF 28.27%);
            border-radius: inherit; 
            mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
            filter: blur(10px);
        }
        &::after {
            content: "";
            position: absolute;
            inset: -4px;
            padding: 7px; 
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
            background: linear-gradient(135deg, #60E7EF 16.27%,  #0B1D26 0%, #000608 86%, #60E7EF 16.27%);
            border-radius: inherit; 
            mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
            filter: blur(10px);
        }
    }

    .d-card-right{
        width: 100%;
        position: relative;
        padding-left: 50px;
        overflow: hidden;
        .card-title{
            @include space-40-normal();
            text-align: left
        }
    }
}


@include between(0,$breakpoint-mobile){
    .d-card-main{
        margin: 5%;
        margin-top: 10%;
        margin-bottom: 10%;
        flex-direction: column;
        .d-card-left{
            width: 380px;
            height: 300px;
            img{
                width: 380px;
                height: 300px;
            }
        }
    
        .d-card-right{
            padding-left: 0px;
        }
    }    
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .d-card-main{
        margin: 5%;
        margin-top: 5%;
        margin-bottom: 5%;
        .d-card-left{
            width: 380px;
            img{
                width: 380px;
            }
        }
        .d-card-right{
            padding-left: 20px;
        }
    }    
}
@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .d-card-main{
        margin: 5%;
        margin-top: 5%;
        margin-bottom: 5%;
        .d-card-left{
            width: 500px;
            img{
                width: 500px;
            }
        }
    }
}
@include between($breakpoint-desktop + 1,$breakpoint-large-desktop){
    .d-card-main{
        .d-card-left{
            width: 580px;
            img{
                width: 580px;
            }
        }
    }
}