@import './../../common/color.scss';

.services-section {
    padding-top: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background-color: $themeBlackBackgroundColor; // Adjust based on your background color
  
    .section-title {
        margin-bottom: 20px;
        display: inline-block;
        position: relative;
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; /* Fallback color */
        @include gilroy-25-bold();
        
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        width: 100px; // Adjust the line length
        height: 2px;
        background: $theme-gradient;
      }      
      &:before {
        left: -120px; // Adjust the left line position
      }
  
      &:after {
        right: -120px; // Adjust the right line position
      }
    }

    .section-description{
        //styleName: description;
        margin-top: 0px;
        margin-bottom: 0px;
        @include space-20();
        color: $white-font-color;
    }

    .servie-card-section{
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 10%;
        margin-right: 10%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap; 
    }
  }


  @include between(0,$breakpoint-mobile){
    .services-section {
    
      .section-title {
          // @include gilroy-25-bold();
        font-size: 20px;          
        &:before,
        &:after {
          width: 25px;
        }      
        &:before {
          left: -40px; // Adjust the left line position
        }
    
        &:after {
          right: -40px; // Adjust the right line position
        }
      }
  
      .section-description{
          font-size: 14px !important;
      }
    }
  }
  @include extra-large{
    .services-section{
        padding-top: 0;
        .servie-card-section{
          margin-left: 8%;
          margin-right: 8%;
        }
    }
}