@import './../../../common/color.scss';

.testimonal-card{
    width: 352px;
    height: 330.62px;
    margin-bottom: 10px;

    .testimonal-card-image{
        box-shadow: 0px 94px 200px 0px #15151526;
        position: relative;
        z-index: 2;
        img{
            width: 126.43px;
            height: 126.43px;
            border-radius: 50%;
            border: 3px solid #FFFFFF;
            object-fit: cover;
        }
    }
    .testimonal-detail{
        position: relative;
        top:-50px;
        width: 352px;
        height: 239px;
        border-radius: 41.67px;
        border: 2.5px solid #FFFFFF66;
        border-left: 0;
        border-right: 0;
        // background: linear-gradient(160.61deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 101.7%);
        backdrop-filter: blur(35.83000183105469px);
        // background-image: url('public/testimonials/testimonial-background.svg');

        // background: 
        // linear-gradient(to bottom 15% 35%, rgba(0, 200, 255, 0.4), transparent 10%), radial-gradient(circle at 85% 20%, rgba(0, 200, 255, 0.4), transparent 10%)
        //     #000; /* Base black color */
        // box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5); /* Outer shadow */

        background: 
        radial-gradient(circle at 18% 30%, rgba(0, 220, 250, 0.4), transparent 20%),
            radial-gradient(ellipse 50% 100% at 90% 15%, rgba(0, 220, 250, 0.4), transparent 20%); /* Right glow */
        box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.5); /* Outer shadow */
        backdrop-filter: blur(10px);
        
        .detail-header{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1.98px 25px 1.96px 25px;
            .header-image{
                width: 71px;
                height: 17px;
            }

            .header-text{
                @include space-11();
                color: $white-font-color;

            }

        }

        .testimonial-name{
            @include space-28-bold();
            color: $white-font-color;
            margin: 10px;
            margin-bottom: 4px;
        }
        .testimonial-data{
            // display: flex;
            // flex-wrap: wrap;
            padding: 6px 20px;

            .testimonial-quo-text{
                @include space-15();
                color: $white-font-color;
                .description-quotation{
                    font-size: 20px;
                    img{
                        height: 25px;
                        width: 37px;
                    }
                }
            }
            
            
            
        }

        .testimonial-review{
            display: flex;
            justify-content: center;
            img{
                width: 34.92px;
                height: 34.92px;
                padding: 5px;

            }
        }
        
    }

}


@include between(0,$breakpoint-mobile){
    .testimonal-card{
        width: 300px;
        height: 230.62px;
        margin-bottom: 10px;
    
        .testimonal-card-image{
            box-shadow: 0px 94px 200px 0px #15151526;
            position: relative;
            z-index: 2;
            img{
                width: 100px;
                height: 100px;
            }
        }
        .testimonal-detail{
            top:-50px;
            width: 300px;
            height: 209px;
            
            .detail-header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 1.5px 25px;
                .header-text{
                    font-size: 9px;    
                }
    
            }
    
            .testimonial-name{
                font-size: 18px;
                margin: 5px;
            }
            .testimonial-data{
                padding: 0px 10px;
    
                .testimonial-quo-text{
                    font-size: 12px;
                    .description-quotation{
                        font-size: 10px;
                        img{
                            height: 15px;
                            width: 25px;
                        }
                    }
                }                
            }            
        }    
    }
  }