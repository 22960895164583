@import './../../common/color.scss';


.career-main{
    background-image: url('./../../../public/backgrounds/Career.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; 
    }
    
    .main-text{
        // background-color: #009FFF;
        width: 100%;
        backdrop-filter: blur(10px);
        margin-top: 1%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 2;
        color: $white-font-color;

        .main-description{
            @include space-80-normal();
            margin-top: 2%;
            text-align: center;
            align-items: center;
        }

        .description{
            margin-bottom: 2%;
            text-align: center;
            align-items: center;
            @include space-50-normal();
        }
    
    }
    
    .bg-jobs {
        position: relative;
        padding-top: 2%;
        
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-position: top;
            object-fit: cover;
        }

        .career-form-container {
            z-index: 2;
            margin:10%;
            margin-top: 0;
            color: $white-font-color;

            .form-title {
                @include space-50-normal();
                text-align: center;
                margin: 20px;

                .job-title{
                    background: $theme-gradient;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
            .cards {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(352px, 1fr));
                gap: 20px;
                margin-top: 20px;
            }
            
        }
          
    }
}

@include between(0,$breakpoint-mobile){
    .career-main{
        .heading-text{
            margin-top: 5%;
            font-size: 40px;
            line-height: 45px;
        }
        
        .main-text{
            margin-top: 4%;    
            .description{
                margin: 0px 5%;
                font-size: 14px;
                line-height: 16px;
            }
        
        }
        
        .bg-jobs {
            .career-form-container {
                margin:5%;   
                .form-title {
                    font-size: 32px;
                    line-height: 34px;
                }
                .career-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            flex-direction: column;
                            label {
                                font-size: 18px;
                                line-height: 20px;
                            }
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                        .consent-label {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .career-main{
        .bg-jobs {    
            .career-form-container {
                .form-title {
                    @include space-40-bold();
                    text-align: center;
                    margin: 20px;
                }
                .career-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            display: flex;    
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-large-desktop){
    .career-main{
        .bg-jobs {    
            .career-form-container {
                .career-form {
                    .form-row {
                        .check-services{ 
                            .checkbox-container {
                                grid-template-columns: repeat(2,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}