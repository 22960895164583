@import './../../common/color.scss';


.contact-main{
    background-image: url('./../../../public/backgrounds/Contact us.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; 
    }
    
    .main-text{
        width: 100%;
        height: 108px;
        backdrop-filter: blur(10px);
        margin-top: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .description{
            margin: 0px 10%;
            text-align: center;
            align-items: center;
            @include space-20();
            color: $white-font-color;
        }
    
    }
    
    .bg-form {
        position: relative;
        padding-top: 5%;
        
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-position: top;
            object-fit: cover;
        }
        
        .gradient-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1;
            background: linear-gradient(
                to bottom, 
                rgba(0, 0, 0, 0.7) 30%, 
                rgba(0, 0, 0, 0) 40%,
                rgba(0, 0, 0, 0.1) 60%,   
                rgba(0, 0, 0, 0.7) 75%
            );
        }

        .contact-form-container {
            position: relative;
            z-index: 2;
            margin: 0 auto;
            padding: 5%;
            padding-top: 3%;
            margin:10%;
            margin-top: 0;
            color: $white-font-color;
            backdrop-filter: blur(20px);

            &::before{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 1px;
                border-radius: inherit;
                background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
                mask: conic-gradient(#000 0 0) content-box exclude,conic-gradient(#000 0 0);
            }

            .form-title {
                @include space-40-bold();
                text-align: center;
                margin: 20px;
            }
            
            .contact-form {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .form-row {
                    display: flex;
                    gap: 20px;

                    .check-services{
                        display: flex;
                        flex-direction: column;
                        flex: 1;

                        label {
                            @include space-25-normal();
                            padding-bottom: 10px;
                        }
                        .checkbox-container label {
                            margin-bottom: 20px;
                        }

                        .checkbox-container {
                            display: grid;
                            grid-template-columns: repeat(3,1fr);
                            padding-left: 10%;
                            margin-bottom: 10px;
                        }

                    }

                    .consent-label {
                        display: flex;     
                        @include space-25-normal();
                        align-items: flex-start;
                        gap: 15px;
                        padding-bottom: 10px;
                        margin-bottom: 20px;
                    }

                    .cm_button{
                        padding: 15px 70px;
                    }
                }
                
                
                .form-group {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                }
               
            }
        }
          
    }
}

@include between(0,$breakpoint-mobile){
    .contact-main{
        .heading-text{
            margin-top: 5%;
            font-size: 40px;
            line-height: 45px;
        }
        
        .main-text{
            margin-top: 4%;    
            .description{
                margin: 0px 5%;
                font-size: 14px;
                line-height: 16px;
            }
        
        }
        
        .bg-form {
            .contact-form-container {
                margin:5%;   
                .form-title {
                    font-size: 32px;
                    line-height: 34px;
                }
                .contact-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            flex-direction: column;
                            label {
                                font-size: 18px;
                                line-height: 20px;
                            }
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                        .consent-label {
                            font-size: 18px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .contact-main{
        .bg-form {    
            .contact-form-container {
                .form-title {
                    @include space-40-bold();
                    text-align: center;
                    margin: 20px;
                }
                .contact-form {
                    .form-row {
                        flex-direction: column;
                        .check-services{
                            display: flex;    
                            .checkbox-container {
                                grid-template-columns: repeat(1,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-large-desktop){
    .contact-main{
        .bg-form {    
            .contact-form-container {
                .contact-form {
                    .form-row {
                        .check-services{ 
                            .checkbox-container {
                                grid-template-columns: repeat(2,1fr);
                            }
                        }
                    }
                }
            }
        }
    }
}