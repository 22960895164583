@import './../../common/color.scss';


.products-main{
    background-image: url('./../../../public/backgrounds/Products.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    color: $white-font-color;
    
    .heading-text{
        margin-top: 8%;
        @include space-80-normal();
        background: $theme-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: $themeBlackBackgroundColor; 
    }
    
    .main-text{
        width: 100%;
        height: 108px;
        backdrop-filter: blur(10px);
        margin-top: 1%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        .description{
            margin: 0px 10%;
            text-align: center;
            align-items: center;
            @include space-20();
            color: $white-font-color;
        }
    
    }

    .bg-product {
        position: relative;
        // overflow: hidden;
        // background-image: url('./../../../public/images/productBg.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // min-height: 100vh;
        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     z-index: 1;
        //     background: linear-gradient(
        //         to bottom, 
        //         rgba(0, 0, 0, 0.7) 30%, 
        //         rgba(0, 0, 0, 0.1) 60%,
        //         rgba(0, 0, 0, 0.1) 50%,   
        //         rgba(0, 0, 0, 0.8) 75%
        //     );
        // }
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            object-position: top;
            object-fit: cover;
        }
        
        .gradient-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1;
            background: linear-gradient(
                to bottom, 
                rgba(0, 0, 0, 0.7) 30%, 
                rgba(0, 0, 0, 0) 40%,
                rgba(0, 0, 0, 0.1) 60%,   
                rgba(0, 0, 0, 0.7) 75%
            );
        }

        .product-sec{
            padding: 10%;
            padding-top: 5%;
            z-index: 2;
            padding-bottom: 0;
            position: relative;
            height: 550px;
            overflow: hidden;
            display: flex;
            justify-content: center;

            &.reversed{
                flex-direction: row-reverse;
            }

            .product-info{
                text-align: left;
                width: 50%;
                overflow: hidden;
                padding-left: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .p-title{
                    @include space-45-bold();
                    text-align: left;
                    margin: 0;
                }
                .p-detailed{
                    height: 390px;
                    overflow: hidden;
                    .p-description{
                        @include space-20();
                        text-align: left;
                    }
                }
                .cm_button{
                    padding: 5px 40px !important;
                }
                .cm_button::before{
                    background: linear-gradient(90deg, #60E7EF 28.27%, #009FFF 80.21%);
                }
            }
            .product-image{
                width: 544px;
                height: 544px;
                display: flex;
                align-items: center;

                img{
                    width: 544px;
                    // height: 544px;
                }
            }
        }
    }
      
}

@include between(0,$breakpoint-mobile){
    .products-main{
        .main-text{
            width: 100%;
            .description{
                margin: 0px 2%;
                font-size: 18px;
                line-height: 20px;
            }        
        }
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(1,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
    
            .process-grid{
                grid-template-columns: repeat(1,1fr);
            }
        }    
    }
}

@include between($breakpoint-mobile + 1,$breakpoint-tablet){
    .products-main{
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
            .process-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
    }
}
@include between($breakpoint-tablet + 1,$breakpoint-desktop){
    .products-main{
       
        
        .benefits-overview{    
            .overview-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
        .our-process{
            flex-direction: column;
            .process-info{
                width: 100%;
            }
    
            .process-grid{
                grid-template-columns: repeat(2,1fr);
            }
        }    
    }
}