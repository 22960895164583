@import './../../common/color.scss';

.sub-template{
    color: $white-font-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;

    .temp-title{
        @include space-40-bold();
        
    }
    .temp-description{
        @include space-20();
        margin-top: 10px;
    }

    .temp-mail{
        display: flex;
        margin-top: 30px;
        max-width: 20%;
        .cm_button{
            padding: 11px 24px 10px 24px;
        }
    }
}